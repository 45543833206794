
import { React, useState } from "react";
import "./Navlink.scss";

const Navlink = (props) => {

  const url = props.url;
  const copy = props.copy;

  const defaultLinkColor = props.styling.footerLinkColor;

  const hoverBottomBorder = props.styling.footerLinkHoverColor;
  const defaultBottomBorder = '2px solid rgba(0,0,0,0)'

  const [linkBorder, setLinkBorder] = useState(defaultBottomBorder);

  if (props.font !== 'default') {
    document.querySelectorAll('.navlink').forEach(item => {
      item.style.fontFamily = 'titleFont';
    })
  } else {
    document.querySelectorAll('.navlink').forEach(item => {
      item.style.fontFamily = 'defaultTitleFont';
    })
  }



  return (
    <div className="col-12 col-xl-auto h-auto text-center text-xl-start my-2 my-xl-0 me-0 me-xl-3" onMouseEnter={() => { setLinkBorder('2px solid ' + hoverBottomBorder) }} onMouseLeave={() => { setLinkBorder(defaultBottomBorder) }}>
      <a href={url} target="_blank" className="navlink" style={{ color: defaultLinkColor, borderBottom: linkBorder }} rel="noreferrer">{copy}</a>
    </div>
  )
}

export default Navlink;

