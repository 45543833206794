import "./Footer.scss";
import { React } from "react";

import Navlink from "../Navlink/Navlink";
import SocialIcon from "../SocialIcon/SocialIcon";
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = (props) => {
  const date = new Date();

  const logo = props.styling.logoFooter.fields.file.url;
  const logoSize = props.styling.logoFooterSize
    ? props.styling.logoFooterSize + "px"
    : "30px";
  const logoUrl = props.styling.brandHomepage;

  const bgColor = props.styling.footerBackgroundColor;
  const textColor = props.styling.footerTextColor;

  const quoteLineOne = props.styling.footerQuoteLine1Color;
  const quoteLineTwo = props.styling.footerQuoteLine2Color;
  const footerBorderColor = props.styling.headerAndFooterBorderColor;

  const showFooter = props.campaign.showFooterQuote;

  const socialUrls = props.styling.socialUrls;

  let socials = [];

  if (socialUrls && socialUrls.length > 0) {
    socialUrls.forEach((url, i) => {
      let socialIcon;
      if (url.indexOf('facebook') !== -1) {
        socialIcon = faFacebookF;
      } else if (url.indexOf('twitter') !== -1) {
        socialIcon = faTwitter;
      } else if (url.indexOf('instagram') !== -1) {
        socialIcon = faInstagram;
      } else if (url.indexOf('youtube') !== -1) {
        socialIcon = faYoutube;
      } else if (url.indexOf('twitter') !== -1) {
        socialIcon = faTwitter;
      } else if (url.indexOf('linkedin') !== -1) {
        socialIcon = faLinkedin;
      }
      socials.push(
        <SocialIcon
          key={'socialIcon' + i}
          href={url}
          icon={socialIcon}
          styling={props.styling}
        />
      );
    });
  }

  const logoClass = showFooter
    ? "col-4 d-flex justify-content-end"
    : "col-12 pt-3 d-flex justify-content-center";

  if (props.fonts !== "default") {
    document.querySelectorAll(".footer__top__copy").forEach((item) => {
      item.style.fontFamily = "headTitleFont";
    });
    document
      .querySelectorAll(".footer__middle__linkContainer--cookieButton")
      .forEach((item) => {
        item.style.fontFamily = "titleFont";
      });
    document
      .querySelectorAll(
        ".footer__bottom__copyright, .footer__middle__socialCopy"
      )
      .forEach((item) => {
        item.style.fontFamily = "paragraphFont";
      });
  } else {
    document.querySelectorAll(".footer__top__copy").forEach((item) => {
      item.style.fontFamily = "defaultHeadTitleFont";
    });
    document
      .querySelectorAll(".footer__middle__linkContainer--cookieButton")
      .forEach((item) => {
        item.style.fontFamily = "defaultTitleFont";
      });
    document
      .querySelectorAll(
        ".footer__bottom__copyright, .footer__middle__socialCopy"
      )
      .forEach((item) => {
        item.style.fontFamily = "defaultParagraphFont";
      });
  }

  return (
    <div
      className="footer"
      style={{
        backgroundColor: bgColor,
        borderTop: `1px solid ${footerBorderColor}`,
      }}
    >
      <div className="container">
        <div className="d-none d-xl-block">
          <div className="footer__top">
            <div className="row align-items-center h-100">
              {showFooter && (
                <div className="col-8">
                  <div className="row align-items-center">
                    <div className="col-2">
                      <div className="footer__top__icon" />
                    </div>
                    <div className="col-10">
                      <span
                        className="footer__top__copy"
                        style={{ color: quoteLineOne }}
                      >
                        De wereld die we morgen willen
                      </span>
                      <br />
                      <span
                        className="footer__top__copy"
                        style={{ color: quoteLineTwo }}
                      >
                        begint met hoe we vandaag zakendoen
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className={logoClass}>
                <a href={logoUrl}>
                  <img
                    className="footer__top__logo"
                    src={logo}
                    alt="brandlogo"
                    style={{ height: logoSize }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer__middle">
          <div className="row g-0 h-100 align-items-center">
            <div className="col-12 col-xl-8 justify-content-xl-start ">
              <div className="footer__middle__linkContainer">
                <div className="row g-0 h-100 d-flex align-items-center">
                  <Navlink
                    className="footer__middle__linkContainer--bold"
                    url={"https://nld.mars.com/contact"}
                    copy="Contact"
                    styling={props.styling}
                    font={props.fonts}
                  />
                  <Navlink
                    className="footer__middle__linkContainer--bold"
                    url={"https://careers.mars.com/nl/nl"}
                    copy="Carrière"
                    styling={props.styling}
                    font={props.fonts}
                  />
                  <div className="col-12 col-xl-auto mb-3 my-xl-0 me-5 align-items-center justify-content-xl-start">
                    <button
                      id="ot-sdk-btn"
                      className="ot-sdk-show-settings footer__middle__linkContainer--cookieButton"
                    >
                      Cookie-instellingen
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {socials.length > 0 && (
              <div className="row g-0  col-12 col-xl-4 align-items-center d-flex justify-content-center justify-content-xl-end">
                <div className="col-12 col-xl-auto text-center text-xl-start pb-2 pb-xl-0">
                  <div
                    className="footer__middle__socialCopy"
                    style={{ color: textColor }}
                  >
                    Volg ons:
                  </div>
                </div>
                {socials}
              </div>
            )}

            <div className="col-12 d-block mt-4  d-xl-none ">
              <div className="footer__middle__divider"></div>
            </div>
          </div>
        </div>

        <div className="footer__bottom">
          <div className="row">
            <div className="col-6 col-xl-12">
              <div className="footer__bottom__linkContainer">
                <div className="row">
                  <Navlink
                    url={"https://www.mars.com/privacy"}
                    target="_blank"
                    copy="Privacyverklaring"
                    styling={props.styling}
                    font={props.fonts}
                  />
                  <Navlink
                    url={"https://www.mars.com/cookies-netherlands"}
                    target="_blank"
                    copy="Cookiemelding"
                    styling={props.styling}
                    font={props.fonts}
                  />
                  <Navlink
                    url={"https://www.mars.com/legal-netherlands"}
                    target="_blank"
                    copy="Juridische"
                    styling={props.styling}
                    font={props.fonts}
                  />
                </div>
              </div>
            </div>

            <div>
              <div
                className="footer__bottom__copyright"
                style={{ color: textColor }}
              >
                <div className="col-12  text-center text-xl-start">
                  © {date.getFullYear()} Mars, Incorporated and its Affiliates.
                  All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
