import "./App.scss";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col, Container } from "react-bootstrap";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Hero from "../Hero/Hero";
import VendorContainer from "../VendorContainer/VendorContainer";
import { isMobile } from "react-device-detect";
import NotFound from "../NotFound/NotFound";
const contentful = require("contentful");

const client = contentful.createClient({
  space: "r72yyy0vxwf0",
  accessToken: "zFlzD_dGyz0JxeLjqJAec1wTTBwhhljW5y9qjlY0R5c",
});


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignName: "",
      campaign: {},
      brand: {},
      ufd: [],
      supermarkets: [],
      isMobile,
      defaultBrand: [],
      headTitleFontLoaded: false,
      titleFontLoaded: false,
      paragraphFontLoaded: false,
      ctaFontLoaded: false,
      defaultHeadTitleFontLoaded: false,
      defaultTitleFontLoaded: false,
      defaultParagraphFontLoaded: false,
      defaultCtaFontLoaded: false,
    };
    this.createFont = this.createFont.bind(this);
    this.getContent = this.getContent.bind(this);
    this.getCampaignFromQueryParams =
      this.getCampaignFromQueryParams.bind(this);
  }
  createFont(fontName, fontUrl, fontState) {
    const fontFace = new FontFace(fontName, 'url(' + fontUrl + ')');
    fontFace.load().then((loadedFont) => {
      document.fonts.add(loadedFont)
      this.setState({
        [fontState]: true
      })
    })
  }

  getCampaignFromQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    let campaignName;
    if (queryParams && queryParams.get("campaign")) {
      campaignName = queryParams.get("campaign").trim();
    }
    this.setState(
      {
        campaignName,
      },
      () => {
        if (this.state.campaignName) {
          this.getContent();
        }
      }
    );
  }
  getContent() {
    const app = this;
    client
      .getEntries({
        "fields.id": this.state.campaignName,
        content_type: "campaign",
      })
      .then(function (entries) {
        if (entries && entries.items.length > 0) {
          const currentCampaign = entries.items[0].fields;
          app.setState({
            campaign: currentCampaign,
            brand: currentCampaign.brand.fields,
            ufd: currentCampaign.pointsOfSale.filter(
              (PoS) => PoS.fields.type === "UFD"
            ),
            supermarkets: currentCampaign.pointsOfSale.filter(
              (PoS) => PoS.fields.type === "Supermarket"
            ),
          });
          app.createFont('headTitleFont', app.state.brand.headTitleFont.fields.file.url, 'headTitleFontLoaded')
          app.createFont('titleFont', app.state.brand.titleFont.fields.file.url, 'titleFontLoaded')
          app.createFont('paragraphFont', app.state.brand.paragraphFont.fields.file.url, 'paragraphFontLoaded')
          app.createFont('ctaFont', app.state.brand.ctaFont.fields.file.url, 'ctaFontLoaded')
        }
      });
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.handleWindowSizeChange);
    }
  }

  handleWindowSizeChange = () => {
    this.setState({
      isMobile
    });
  };

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.handleWindowSizeChange);
    }
  }

  componentDidMount() {
    const app = this;
    client
      .getEntries({
        content_type: 'brand',
        'fields.name': 'Mars',
      })
      .then(function (entries) {
        entries.items.forEach(function (entry) {
          app.setState({
            defaultBrand: entry.fields
          })
        });
        app.createFont('defaultHeadTitleFont', app.state.defaultBrand.headTitleFont.fields.file.url, 'defaultHeadTitleFontLoaded')
        app.createFont('defaultTitleFont', app.state.defaultBrand.titleFont.fields.file.url, 'defaultTitleFontLoaded')
        app.createFont('defaultParagraphFont', app.state.defaultBrand.paragraphFont.fields.file.url, 'defaultParagraphFontLoaded')
        app.createFont('defaultCtaFont', app.state.defaultBrand.ctaFont.fields.file.url, 'defaultCtaFontLoaded')
      });

    this.getCampaignFromQueryParams();
    this.setState({
      isMobile,
    });
  }

  render() {
    if (this.state.brand.name && this.state.campaign.name) {
      return (
        <div className="App">
          <Header styling={this.state.brand} />
          <Container fluid>
            <Row className="pageContent">
              <Col sm={12} xl={5} style={{ padding: "0", backgroundColor: this.state.brand.heroBackgroundColor }}>
                <Hero campaign={this.state.campaign} styling={this.state.brand} />
              </Col>
              <Col className="row align-items-xl-center g-0 d-xl-flex justify-content-xl-center" sm={12} xl={7} style={{ padding: "0", backgroundColor: this.state.brand.mainBackgroundColor }}>
                <VendorContainer campaign={this.state.campaign} supermarkets={this.state.supermarkets} ufd={this.state.ufd} mobile={this.state.isMobile} styling={this.state.brand} />
              </Col>
            </Row>
          </Container>
          <Footer campaign={this.state.campaign} styling={this.state.brand} />
        </div>
      );
    } else {
      if (this.state.defaultBrand.name) {
        return <div className="App">
          <Header styling={this.state.defaultBrand} />
          <Col style={{ backgroundColor: this.state.defaultBrand.heroBackgroundColor }} >
            <NotFound styling={this.state.defaultBrand} />
          </Col>
          <Footer campaign={{ showFooterQuote: true }} styling={this.state.defaultBrand} fonts={'default'} />
        </div>
      } else {
        return <div className="App"></div>
      }
    }
  }
}

export default App;
