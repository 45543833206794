import "./VendorContainer.scss";
import { React, useState } from "react";
import Slider from "../Slider/Slider";

const VendorContainer = (props) => {
  const shopCopyDesktop = props.campaign.shopCopyDesktop;
  const shopCopyMobile = props.campaign.shopCopyMobile;
  const campaignInfo = props.campaign.campaignInfo;
  const campaignInfoCta = props.campaign.campaignInfoCta;
  const campaignInfoUrl = props.campaign.campaignInfoUrl;
  const supermarkets = props.supermarkets.length;
  const ufd = props.ufd.length;
  const textColor = props.styling.mainTextColor;
  const defaultCtaBgColor = props.styling.ctaBackgroundColor;
  const defaultCtaTextColor = props.styling.ctaTextColor;
  const ctaHoverBgColor = props.styling.ctaBackgroundHoverColor;
  const ctaHoverTextColor = props.styling.ctaTextHoverColor;
  document
    .querySelectorAll(".vendorContainer__title, .vendorContainer__campaignInfo")
    .forEach((item) => {
      item.style.fontFamily = "paragraphFont";
    });

  document
    .querySelectorAll(".vendorContainer__campaignInfo__cta")
    .forEach((item) => {
      item.style.fontFamily = "ctaFont";
    });

  const [ctaBackgroundColor, setCtaBackgroundColor] =
    useState(defaultCtaBgColor);
  const [ctaTextColor, setCtaTextColor] = useState(defaultCtaTextColor);

  return (
    <div className="vendorContainer">
      <div className="container mt-3 h-100 g-0 g-xl-2 overflow-hidden overflow-xl-auto">
        <div className="row h-100 align-items-center mb-5">
          <div className="col-12 d-flex justify-content-center mb-2">
            <div
              className="vendorContainer__title"
              style={{
                color: textColor,
              }}
            >
              {props.mobile ? shopCopyMobile : shopCopyDesktop}
            </div>
          </div>
          {supermarkets > 0 && (
            <Slider
              title={props.campaign.supermarktenText || "Supermarkten"}
              vendors={props.supermarkets}
              mobile={props.mobile}
              styling={props.styling}
              fontSize={props.campaign.titleFontSize}
            />

          )}
          {ufd > 0 && (
            <Slider
              title={props.campaign.fastDeliveryText || "Fast delivery"}
              vendors={props.ufd}
              mobile={props.mobile}
              styling={props.styling}
              fontSize={props.campaign.titleFontSize}
            />
          )}
          {campaignInfo && campaignInfoCta && (
            <div
              className="vendorContainer__campaignInfo"
              style={{ color: textColor }}
            >
              <div className="row mt-2 mb-4 d-flex justify-content-center">
                <div className="col-10">{campaignInfo}</div>
                <div className="col-12 mt-2 mt-xl-3 ">
                  <a
                    href={campaignInfoUrl}
                    target="_blank"
                    className="vendorContainer__campaignInfo__cta"
                    style={{
                      backgroundColor: ctaBackgroundColor,
                      color: ctaTextColor,
                    }}
                    onMouseLeave={() => {
                      setCtaBackgroundColor(defaultCtaBgColor);
                      setCtaTextColor(defaultCtaTextColor);
                    }}
                    onMouseEnter={() => {
                      setCtaBackgroundColor(ctaHoverBgColor);
                      setCtaTextColor(ctaHoverTextColor);
                    }}
                    rel="noreferrer"
                  >
                    {campaignInfoCta}
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VendorContainer;
